import config from '@shared/config';

const BASE_ADMIN_TRANSACTION_MANAGEMENT_URL = `${config.adminApiUrl}/api/v1/admin/transaction-management`;
const BASE_MARKETPLACES_URL = `${config.adminApiUrl}/api/v1/admin/marketplaces`;
const BASE_ADMIN_URL = `${config.adminApiUrl}/api/v1/admin`;
const BASE_REFERRAL_ADMIN_URL = `${config.adminApiUrl}/api/admin`;
const BASE_ADMIN_TAGS_SETTINGS_URL = `${config.adminApiUrl}/api/v1/admin/tags`;

export const adminTransactionManagementRoutes = {
  getSales: `${BASE_ADMIN_TRANSACTION_MANAGEMENT_URL}/sale/list`,
  getSystemOverview: `${BASE_ADMIN_TRANSACTION_MANAGEMENT_URL}/sale/system-overview`,
  exportSales: `${BASE_ADMIN_TRANSACTION_MANAGEMENT_URL}/sale/export`,
  getDeposit: `${BASE_ADMIN_TRANSACTION_MANAGEMENT_URL}/deposit/system-overview`,
  getWithdraws: `${BASE_ADMIN_TRANSACTION_MANAGEMENT_URL}/withdraw/system-overview`,
  getWithdrawsList: `${BASE_ADMIN_TRANSACTION_MANAGEMENT_URL}/withdraw/list`,
  getDepositTransactions: `${BASE_ADMIN_TRANSACTION_MANAGEMENT_URL}/deposit/list`,
  getSalesReceipt: (id: number) =>
    `${BASE_ADMIN_TRANSACTION_MANAGEMENT_URL}/sale/receipt/${id}`,
};

export const adminSystemBalanceRoutes = {
  getSystemBalance: `${BASE_ADMIN_URL}/management/system/overview`,
  getSystemReferrals: `${BASE_REFERRAL_ADMIN_URL}/referral/statistic`,
};

export const adminTrendingTokensRoutes = {
  getTrendingTokens: `${BASE_ADMIN_URL}/trending-tokens`,
  getTokenDetail: (tokenId: string) =>
    `${BASE_ADMIN_URL}/trending-tokens/${tokenId}`,
  getTokensFromCoinGecko: (paramString: string) => `${BASE_ADMIN_URL}/trending-tokens/tokens-list?${paramString}`,
  updateTrendingTokens: `${BASE_ADMIN_URL}/trending-tokens`,
};

export const adminNowPaymentsSettingsRoutes = {
  updateAdminNowPaymentsBurnAndStakeSettings: `${BASE_ADMIN_URL}/now-payment/settings`,
  getAdminNowPaymentSettings: `${BASE_ADMIN_URL}/now-payment/settings`,
};

export const adminFeeSettingsRoutes = {
  getAdminFeeSettings: `${config.adminApiUrl}/api/admin/fee-settings`,
  updateAdminFeeSettings: (id: number) =>
    `${config.adminApiUrl}/api/admin/fee-settings${id}`,
  getAdminFeeSiteSettings: `${config.adminApiUrl}/api/admin/fee-settings/site-settings`,
  updateAdminFeeSiteSettings: `${config.adminApiUrl}/api/admin/fee-settings/site-settings`,
};

export const adminSettlementSettingsRoutes = {
  getAdminSettlementSettings: `${config.adminApiUrl}/api/admin/settlement-settings`,
  updateAdminSettlementSettings: (id: number) =>
    `${config.adminApiUrl}/api/admin/settlement-settings/${id}`,
};

export const adminNowpaymentsRoutes = {
  transactions: `${BASE_ADMIN_URL}/now-payment/transactions`,
  statistics: `${BASE_ADMIN_URL}/now-payment/statistics`,
};

export const adminProductSubmissionsRoutes = {
  getSubmissions: `${BASE_MARKETPLACES_URL}/submissions`,
  getAdminSubmissionDetails: (id: number) =>
    `${config.adminApiUrl}/api/v1/admin/marketplaces/submissions/${id}`,
  approveSubmission: (id: string) =>
    `${BASE_MARKETPLACES_URL}/submissions/${id}/approve`,
  rejectSubmission: (id: string) =>
    `${BASE_MARKETPLACES_URL}/submissions/${id}/reject`,
};

export const adminMarketplacesRoutes = {
  getMarketplaceProducts: `${BASE_MARKETPLACES_URL}/products`,
  getMarketplaceProductDetails: (id: number) =>
    `${BASE_MARKETPLACES_URL}/products/${id}`,
  changeProductVisibility: (id: number) =>
    `${BASE_MARKETPLACES_URL}/products/${id}/change-visibility`,
};

export const adminRefundRoutes = {
  getRefundRequests: `${BASE_ADMIN_URL}/refund-requests`,
  getRefundHistories: (id: number) =>
    `${BASE_ADMIN_URL}/refund-requests/${id}/histories`,
  approveRefundRequest: (id: number) =>
    `${BASE_ADMIN_URL}/refund-requests/${id}/approve`,
  rejectRefundRequest: (id: number) =>
    `${BASE_ADMIN_URL}/refund-requests/${id}/reject`,
};

export const adminFeaturedProductsRoutes = {
  getFeaturedProducts: `${BASE_ADMIN_URL}/featured-products`,
  updateFeaturedProducts: `${BASE_ADMIN_URL}/featured-products`,
  getSearchProductList: `${BASE_ADMIN_URL}/featured-products/products-list`,
};

export const adminHomepageBannersRoutes = {
  getHomepageBanners: `${BASE_ADMIN_URL}/home-banners`,
  updateHomepageBanners: `${BASE_ADMIN_URL}/home-banners`,
};

export const adminTagsSettingsRoutes = {
  tagsSettingsBase: BASE_ADMIN_TAGS_SETTINGS_URL,
  updateTagSettings: (id: number) => `${BASE_ADMIN_TAGS_SETTINGS_URL}/${id}`,
  deleteTagSettings: (id: number) => `${BASE_ADMIN_TAGS_SETTINGS_URL}/${id}`,
};
