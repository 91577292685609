import {
  IChangeVisibilityParams,
  IProduct,
  PaginatedResponseWrapper,
  ResponseWrapper,
  UserGetProductsDetailResponse,
} from '@interfaces';
import { IFetchParams } from '@interfaces/queryParams';

import { withAuthenticatedAdminClientRequest } from '@api/requestBuilder/client/withAuthenticatedAdminClientRequest';
import {
  appendParamsToUrl,
  formatDateParams,
} from '@api/requestBuilder/helpers';
import { withAuthenticatedAdminServerRequest } from '@api/requestBuilder/server/withAuthenticatedAdminServerRequest';
import { ICallableRequestBuilder } from '@api/requestBuilder/types';

import { adminMarketplacesRoutes } from '../adminApi.routes';

export interface IProductAdmin extends IProduct {
  productStatistic: {
    countUseApp: number;
    countOpenIframe: number;
  }
}

const getProducts =
  (request: ICallableRequestBuilder<PaginatedResponseWrapper<IProductAdmin[]>>) =>
    async (params?: IFetchParams, timeZone?: string) => {
      const formattedParams = formatDateParams(params, timeZone);
      const url = appendParamsToUrl(
        adminMarketplacesRoutes.getMarketplaceProducts,
        formattedParams
      );
      return await request.call(url);
    };

const getProductDetails =
  (request: ICallableRequestBuilder<UserGetProductsDetailResponse>) =>
    async (productId: number) => {
      const url = adminMarketplacesRoutes.getMarketplaceProductDetails(productId);

      return request.call(url);
    };

const changeVisibility =
  (request: ICallableRequestBuilder<ResponseWrapper<null>>) =>
    async (data: IChangeVisibilityParams) => {
      const url = adminMarketplacesRoutes.changeProductVisibility(data.productId);
      return await request.call(url, (init) => ({
        ...init,
        method: 'POST',
        headers: {
          ...init.headers,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      }));
    };

export const marketplacesApi = {
  client: {
    getProducts: withAuthenticatedAdminClientRequest(getProducts),
    getProductDetails: withAuthenticatedAdminClientRequest(getProductDetails),
    changeVisibility: withAuthenticatedAdminClientRequest(changeVisibility),
  },
  server: {
    getProducts: withAuthenticatedAdminServerRequest(getProducts),
    getProductDetails: withAuthenticatedAdminServerRequest(getProductDetails),
    changeVisibility: withAuthenticatedAdminServerRequest(changeVisibility),
  },
};
